import { tns } from "tiny-slider/src/tiny-slider";

export class ProgramSlider {

    constructor() {
        this.slider = tns({
            container: '.program-slider',
            controlsContainer: '.program-controls',
            items: 1,
            autoHeight: true,
            mouseDrag: true,
            nav: false,
            // navContainer: '.datepicker-slider',
            loop: false,
            preventScrollOnTouch: 'auto',
        });

        this.init();
        // this.initDatepicker();
    }

    // Initalize slider
    init() {

        // NB! Hack that may not be needed in prod, but to be on the safe side
        setTimeout(this.slider.updateSliderHeight, 500);

        // Check for expired shows every minute
        setInterval(this.disableShows.bind(this), 60*1000);
    }

    // Check every minute if any shows have started, and grey them out
    disableShows() {

        let info = this.slider.getInfo();

        let now = new Date();
        // Get all shows on today's slide (the only relevant one)
        let shows = info.slideItems[0].getElementsByClassName('show');

        // Loop throgh shows and see if any has started
        for (let i = 0; i < shows.length; i++) {

            let showTime = new Date(shows[i].dataset.showtime);
            if (showTime.getTime() < now.getTime()) {
                shows[i].classList.add('in-past');
            }
        }
    }

    initDatepicker() {

        var dateHeaders = document.getElementsByClassName("date-header");
        for (let i = 0; i < dateHeaders.length; i++) {
            dateHeaders[i].onclick = function() {
                document.getElementById("program-nav").classList.toggle("hidden");
                document.getElementsByClassName("program-wrapper")[0].classList.toggle("blur");
                document.getElementsByTagName("body")[0].classList.toggle("cursor-pointer");
            }
        }

        // Close the dropdown menu if the user clicks outside of it
        window.onclick = function(event) {
            if (!event.target.matches('.date-header')) {
                var openDropdown = document.getElementById("program-nav");
                if (!openDropdown.classList.contains('hidden')) {
                    openDropdown.classList.add('hidden');
                }
                var programWrapper = document.getElementsByClassName("program-wrapper")[0];
                if (programWrapper.classList.contains('blur')) {
                    programWrapper.classList.remove('blur');
                }
                var body = document.getElementsByTagName("body")[0];
                if (body.classList.contains('cursor-pointer')) {
                    body.classList.remove('cursor-pointer');
                }

            }
        }
    }
}
