import Pikaday from 'pikaday';

export class Datepicker {

    constructor() {
        this.enabledDates = this.getEnabledDates();
        this.lastDay = this.enabledDates[ this.enabledDates.length-1 ];

        this.init();
    }


    init() {
        // Create Pikaday instance

        this.picker = new Pikaday({
                            field: document.getElementById('datepicker'),
                            //container: document.getElementById('datepicker-container'),
                            bound: true,
                            format: 'YYYY-MM-D',
                            defaultDate: new Date(),
                            setDefaultDate: true,
                            minDate: new Date(),
                            maxDate: new Date(this.lastDay),
                            firstDay: 1,
                            showDaysInNextAndPreviousMonths: true,
                            enableSelectionDaysInNextAndPreviousMonths: true,
                            disableDayFn: this.isDisabled.bind(this),
                            onSelect: this.onSelect.bind(this),
                        });

        // Add onClick to dateheaders to set date and open datepicker
        let dateHeaders = document.getElementsByClassName('date-header');
        for (let i = 0; i < dateHeaders.length; i++) {
            dateHeaders[i].onclick = (function () {
                let date = dateHeaders[i].getAttribute('data-date');
                this.picker.setDate(new Date(date), true);

                this.picker.show();
                //this.toggleContainer();
            }).bind(this);
        }
    }


    // Callback function to disable dates without shows
    isDisabled(date) {

        let dateString = this.dateToString(date);
        let enabledDates = this.enabledDates;

        if (enabledDates.includes(dateString)) {
            return false;
        } else {
            return true;
        }
    }

    // Callback function to change program slider when date is clicked
    onSelect(date) {

        let enabledDates = this.enabledDates;
        let dateString = this.dateToString(date);
        let index = enabledDates.indexOf(dateString);

        if(index >= 0) {
            pickerGoTo(index);
        }
    }

    // Callback function to toggle container when opening/closing
    toggleContainer() {

        let container = document.getElementById('datepicker-container');
        let test = container.classList.toggle('hidden');

    }

    // Helper function to format strings
    dateToString(date) {
        let dateString = [
          date.getFullYear(),
          ('0' + (date.getMonth() + 1)).slice(-2),
          ('0' + date.getDate()).slice(-2)
        ].join('-');

        return dateString;
    }

    // Helper function to get which dates have shows
    getEnabledDates() {
        let dates = document.getElementsByClassName('date-header');
        let enabledDates = [];

        for (let i = 0; i < dates.length; i++) {
            let date = dates[i].getAttribute('data-date');
            enabledDates.push(date);
        }

        return enabledDates;
    }


}
