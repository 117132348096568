export class GoogleMap {
    constructor() {
        this.init()
    }

    init() {
        // Load Google map
        // NB! Is this needed? Or at least either this or the next?
        window.initMap = function() {
          let pos = {lat: 52.49159660000001, lng: 13.433056699999952};
          let map = new google.maps.Map(document.getElementById('footer-map'), {
            zoom: 14,
            center: pos,
            gestureHandling: 'cooperative'
          });
          let marker = new google.maps.Marker({
            position: pos,
            map: map
          });
        };
    }

    initMap() {
      let pos = {lat: 52.49159660000001, lng: 13.433056699999952};
      let map = new google.maps.Map(document.getElementById('footer-map'), {
        zoom: 14,
        center: pos,
        gestureHandling: 'cooperative'
      });
      let marker = new google.maps.Marker({
        position: pos,
        map: map
      });
    }

}
