import { modal } from "tingle.js/src/tingle";

export class MoviePopup {

    constructor() {
        this.modal = new modal({
            footer: false,
            stickyFooter: false,
            closeMethods: ['overlay', 'button', 'escape'],
            closeLabel: "Close",
        });

        this.init();
    }

    init() {
        let triggers = document.querySelectorAll('[data-open]');
        for (let i = 0; i < triggers.length; i++) {
            let popupId = triggers[i].getAttribute('data-open');
            triggers[i].onclick = function() {
                this.open(popupId);
            }.bind(this);
        }
    }

    open(popupId) {
        this.modal.setContent(document.getElementById(popupId).innerHTML);
        this.modal.open();
    }
}
