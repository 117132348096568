import { tns } from "tiny-slider/src/tiny-slider";

export class PictureSlider {

    constructor() {

        this.slider = tns({
            container: '#frontpage-gallery',
            controlsContainer: '.picture-controls',
            items: 1,
            nav: false,
            loop: true,
            preventScrollOnTouch: 'auto',
        });

        this.init();
    }

    // Initalize slider
    init() {
        // NB! Hack that may not be needed in prod, but to be on the safe side
        // setTimeout(this.slider.updateSliderHeight, 500)
    }
}
