// Import our CSS
import styles from '../css/app.pcss';

// Node modules
//import microtip from 'microtip/microtip.css';
//import Pikaday from 'pikaday';

// Custom modules
import { ProgramSlider } from "./modules/programslider.js";
import { MovieSlider } from "./modules/movieslider.js";
import { PictureSlider } from "./modules/pictureslider.js";
import { MoviePopup } from "./modules/moviepopup.js";
import { Datepicker } from "./modules/datepicker.js";
import { GoogleMap } from "./modules/googlemap.js";

// Get it all running
export default class krisSite{
    constructor() {
        krisSite.domReady().then(this.ready.bind(this));
    }

    ready() {
        this.applyProgramSlider();
        this.applyMovieSlider();
        this.applyPictureSlider();
        this.applyMoviePopup();
        this.applyDatepicker();
        this.applyGoogleMap();
        // für jedes Module ein this.applyMODULENAME
    }

    // TODO: Pretty sure I can add a config file with exports,
    // and loop through it to create sliders
    applyProgramSlider() {
        let ps = new ProgramSlider;
        window.pickerGoTo = ps.slider.goTo;
    }

/*** TEST START ***/

    applyMovieSlider() {
        new MovieSlider;
    }

    applyPictureSlider() {
        new PictureSlider;
    }

//    applyMovieSlider() {
//        import( /*webpackChunkName: "MovieSlider" */ './modules/movieslider.js')
//            .then((MovieSlider) => {
//                new MovieSlider.default();
//            });
//    }

//    applyPictureSlider() {
//        import( /*webpackChunkName: "PictureSlider" */ './modules/pictureslider.js')
//            .then((PictureSlider) => {
//                new PictureSlider.default();
//            });
//    }
/*** TEST END ***/

    applyMoviePopup() {
        new MoviePopup;
    }

/*** TEST START ***/
    applyDatepicker() {
        new Datepicker;
    }
//    applyDatepicker() {
//        import( /*webpackChunkName: "Datepicker" */ './modules/datepicker.js')
//            .then((Datepicker) => {
//                new Datepicker.default();
//            });
//    }
/*** TEST END ***/

    applyGoogleMap() {
        let gm = new GoogleMap;
        window.initMap = gm.initMap.bind(gm);
    }

  // für jedes Modul ein weiteres applyMODULNAME

    static domReady() {
        return new Promise((resolve) => {
            document.addEventListener('DOMContentLoaded', resolve);
        });
    }
}
new krisSite();

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}




// To be reviewed
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import whatinput from "what-input";
import motionui from "motion-ui";
import jQueryBridget from "jquery-bridget";


require( "lazyloadxt/dist/jquery.lazyloadxt.extra.min.js" );
require( "lazyloadxt/dist/jquery.lazyloadxt.autoload.min.js" );
require( "lazyloadxt/dist/jquery.lazyloadxt.srcset.min.js" );
